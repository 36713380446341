
import {
  IAlert,
  IDataNameFilter,
  IResponseData,
  RouterName,
  StatusTexts,
} from "@/models/common";
import {
  ICancelRequestParam,
  IGetRequestListOptions,
  RequestListType,
  RequestLiveStatuses,
  RequestStatuses,
} from "@/models/request";
import { computed, defineComponent, PropType, reactive, ref, Ref } from "vue";
import RequestCard from "@/components/common/Request/RequestCard.vue";
import DateNameFilter from "@/components/common/DateNameFilter.vue";
import DateFormat from "@/utils/date-format";
import { useI18n } from "vue-i18n";
import { IRequestListItem, RequestKindTypes } from "@/models/request";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import CustomConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";
import { AxiosResponse } from "node_modules/axios";
import $API from "@/services";
import { useConfirm } from "primevue/useconfirm";
import router from "@/router";
import { isArray } from "lodash";
import { RouteRecordName } from "vue-router";
import { messageType } from "@/models/message";

export default defineComponent({
  name: "Request Card List",
  props: {
    type: {
      type: Number as PropType<RequestListType>,
      required: true,
    },
    kind: {
      type: String as PropType<RequestKindTypes>,
      required: true,
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();

    const { t } = useI18n({ useScope: "global" });

    const state = reactive({
      list: [] as Array<IRequestListItem>,
      isActive: true,
      type: props.type,
      isMouseOver: false,
      kind: props.kind,
      isVisibleCancel: true,
      isShow: props.isShow,
    });

    const queryNames = {
      from: state.type === RequestListType.Request ? "reqF" : "resF",
      to: state.type === RequestListType.Request ? "reqT" : "resT",
      name: state.type === RequestListType.Request ? "reqN" : "resN",
    };

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const reqStatusDesc = computed(() => [
      state.kind === RequestKindTypes.OnlineCare ? "예약확정" : "결제확인",
      "신청 및 결제대기",
      "환불처리",
    ]);
    const resStatusDesc = computed(() => ["완료", "환불완료 및 반려", "취소"]);

    const statusDesc = computed(() =>
      state.type === RequestListType.Request
        ? reqStatusDesc.value
        : resStatusDesc.value
    );

    const query = router.currentRoute.value.query;

    const filterState: IDataNameFilter = reactive({
      from:
        !isArray(query[queryNames.from]) && query[queryNames.from]?.toString()
          ? query[queryNames.from]?.toString() ??
            DateFormat.getStringSetMonth(-1)
          : DateFormat.getStringSetMonth(-1),
      to:
        !isArray(query[queryNames.to]) && query[queryNames.to]?.toString()
          ? query[queryNames.to]?.toString() ?? DateFormat.getStringNow()
          : DateFormat.getStringNow(),
      name:
        !isArray(query[queryNames.name]) && query[queryNames.name]?.toString()
          ? query[queryNames.name]?.toString() ?? ""
          : "",
    });

    const isRequest = computed(() => state.type === RequestListType.Request);

    const newRequest = computed(() => {
      return {
        name:
          state.kind === RequestKindTypes.OnlineCare
            ? RouterName.ONLINE_CARE_REQUEST_NEW
            : RouterName.SECOND_OPINION_REQUEST_NEW,
      };
    });

    const getReqeustList = async (filterState: IDataNameFilter) => {
      const options: IGetRequestListOptions = {
        RequestKinds: state.kind,
        IsLive: RequestLiveStatuses.Live,
        DateStart: filterState.from.replaceAll("-", ""),
        DateEnd: filterState.to.replaceAll("-", ""),
      };

      const res: AxiosResponse<IResponseData<IRequestListItem>> =
        await $API.RequestService.getRequestList(options);
      const {
        data: { ResultData },
      } = res;

      if (!Array.isArray(ResultData)) return;
      ResultData.forEach((item) => {
        item.type = RequestListType.Request;
      });
      state.list = [...ResultData];
      if (filterState.name.trim() !== "")
        state.list = state.list.filter(
          (item) =>
            item.ReferredDrName?.includes(filterState.name) ||
            item.DesiredDrName?.includes(filterState.name) ||
            item.DocFacilityName?.includes(filterState.name) ||
            item.DesiredDrDeptName?.includes(filterState.name) ||
            item.ReferredDrDeptName?.includes(filterState.name)
        );
    };

    const add = computed(() => {
      return {
        name:
          state.kind === RequestKindTypes.OnlineCare
            ? RouterName.ONLINE_CARE_REQUEST_NEW
            : RouterName.SECOND_OPINION_REQUEST_NEW,
      };
    });

    const path = reactive({
      add,
    });

    const getResponseList = async (filterState: IDataNameFilter) => {
      const options = {
        DateStart: filterState.from.replaceAll("-", ""),
        DateEnd: filterState.to.replaceAll("-", ""),
        RequestKinds: state.kind,
        IsLive: RequestLiveStatuses.Terminated,
      };

      const res: AxiosResponse<IResponseData<IRequestListItem>> =
        await $API.RequestService.getRequestList(options);

      const {
        data: { ResultData },
      } = res;

      if (!Array.isArray(ResultData)) return;
      ResultData.forEach((item) => {
        item.type = RequestListType.Response;
      });
      state.list = [...ResultData];
    };

    const getList = async () => {
      try {
        store.commit(CommonMutationTypes.SET_IS_LODING, true);
        if (state.type === RequestListType.Request)
          await getReqeustList(filterState);
        else if (state.type === RequestListType.Response)
          await getResponseList(filterState);
      } catch (e) {
        console.error(e);
      } finally {
        store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    };

    const filtedList = computed(() => {
      return state.list.filter((item) => {
        if (
          state.isVisibleCancel ||
          item.RequestStatus !== RequestStatuses.Canceled
        )
          return item;
      });
    });

    const requestcancelConfirm = useConfirm();
    const cancelConfirm = (requestKey: number) => {
      requestcancelConfirm.require({
        header: "예약취소",
        message: "해당 예약을 취소하시겠습니까? 취소 후 복구가 불가능합니다.",
        acceptClass: "p-button-danger",
        acceptLabel: t("Yes"),
        rejectLabel: t("No"),
        group: "cancel",
        accept: async () => {
          const cancelRequestParam: ICancelRequestParam = {
            RequestKey: requestKey,
          };
          const res: AxiosResponse = await $API.RequestService.cancelRequest(
            cancelRequestParam
          );
          if (res.statusText === StatusTexts.OK || res.status === 200) {
            state.list = [];
            getList();
          }
          requestcancelConfirm.close();
        },
        reject: () => {
          requestcancelConfirm.close();
        },
      });
    };

    return {
      state,
      isRequest,
      t,
      newRequest,
      RequestListType,
      filterState,
      getResponseList,
      store,
      CommonMutationTypes,
      path,
      getList,
      cancelConfirm,
      filtedList,
      getReqeustList,
      RequestKindTypes,
      statusDesc,
      queryNames,
      alert,
    };
  },
  async mounted() {
    await this.onSearch(this.filterState);
  },
  methods: {
    /**
     * Request Cancel button Click Event
     */
    CancelRequest(requestKey: number) {
      this.cancelConfirm(requestKey);
    },

    toggleActive() {
      this.state.isActive = !this.state.isActive;
    },

    /**
     * filter Event
     */
    async onSearch(filterState: IDataNameFilter) {
      this.state.list = [];
      if (filterState.name.length > 0 && filterState.name.trim().length < 2) {
        this.alert = {
          title: "공백 제외 두글자 이상 입력하세요.",
          content: this.t("검색 오류"),
          type: messageType.INFO,
        };
      } else {
        try {
          this.store.commit(CommonMutationTypes.SET_IS_LODING, true);
          if (filterState.from === "")
            filterState.from = DateFormat.getStringSetMonth(-1);
          if (filterState.to === "") filterState.to = DateFormat.getStringNow();
          this.filterState = filterState;

          const from = this.queryNames.from;
          const to = this.queryNames.to;
          const name = this.queryNames.name;

          const currentQuery = router.currentRoute.value.query;
          const query: any = { ...currentQuery };
          query[from] = filterState.from;
          query[to] = filterState.to;
          query[name] = filterState.name;

          //필터 검색 주소창에 표시
          this.$router.push({
            name: this.$router.currentRoute.value.name as RouteRecordName,
            query,
          });
          this.state.list = [];

          //따로
          if (this.state.type === RequestListType.Request)
            await this.getReqeustList(filterState);
          else if (this.state.type === RequestListType.Response)
            await this.getResponseList(filterState);

          //같이
          // await this.getReqeustList(this.filterState);
          // await this.getResponseList(this.filterState);

          // if (this.filterState.name !== "")
          //   this.state.list = this.state.list.filter((item) => {
          //     if (
          //       (item.ReferredDrName ?? item.DesiredDrName ?? "").includes(
          //         this.filterState.name
          //       )
          //     )
          //       return item;
          //   });
        } catch (e) {
          console.error(e);
        } finally {
          this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
        }
      }
    },
  },

  components: {
    RequestCard,
    CustomConfirmDialog,
    DateNameFilter,
  },
});
