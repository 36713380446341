export interface pdfDoc{
  "title":String;  
  "Opinions":String;
  // "OtherComments":String;
  "info":Array<pdfDocContents>;
}
export interface pdfDocContents{
  "subTitle":String;
  "content":String;
}

export enum ResponseStatuses {
  Ready=0, 
  Scheduled=1,
  Complete=2,
  Refused=3,
  Closed=4
}