
import {
  IRequestListItem,
  RequestKindTypes,
  RequestListType,
  RequestStatuses,
} from "@/models/request";
import DateFormat from "@/utils/date-format";
import { computed, defineComponent, onMounted, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { ResponseStatuses } from "@/models/response";
import { RoleNames } from "@/models/account";
import { RouteLocationRaw } from "vue-router";
import router from "@/router";
import RequestProgress from "@/components/common/Request/RequestProgress.vue";
import RequestProgressDesc from "@/components/common/Request/RequestProgressDescription.vue";
import RequestInfo from "./RequestInfo";
import { RouterName } from "@/models/common";

export default defineComponent({
  name: "Request Card",
  props: {
    item: {
      type: Object as PropType<IRequestListItem>,
      required: true,
    },
    kind: {
      type: String as PropType<RequestKindTypes>,
      required: true,
    },
    type: {
      type: Number as PropType<RequestListType>,
      required: true,
    },
  },
  emits: ["CancelRequest"],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n({ useScope: "global" });

    const isOnlineCare = computed(
      () => props.kind === RequestKindTypes.OnlineCare
    );
    const isRequest = computed(() => props.type === RequestListType.Request);

    const roleName = store.getters.getAccountInfo?.RoleName;

    const state = reactive({
      item: props.item,
      kind: props.kind,
      type: props.type,
      isOnlineCare,
      isRequest,
      isLoading: true,
      isQuickRequest: !!props.item.RequestType,
    });

    const navigatorTo = (path: RouteLocationRaw) => {
      router.push(path);
    };

    const insertDateTime = computed(() => {
      return `${
        DateFormat.splitDatetime(state.item.InsertDateTime.valueOf()).date
      } 신청`;
    });

    onMounted(() => {
      state.isLoading = false;
    });

    const onCancel = () => {
      emit("CancelRequest", state.item.RequestKey);
    };

    const cardInfo = RequestInfo(state, t, onCancel);

    const ScheduledDateTime = computed(() => {
      const t = DateFormat.splitDatetimeStringToString(
        state.item.ScheduledDateTime
          ? state.item.ScheduledDateTime.valueOf()
          : state.item.DesiredDateTime1.valueOf()
      );
      console.log("isQuickRequest", props.item.RequestType);
      return state.isQuickRequest ? "빠른 진료 신청" : t;
    });

    //자세히보기
    const view = computed(() => {
      return {
        name:
          state.kind === RequestKindTypes.OnlineCare
            ? RouterName.ONLINE_CARE_REQUEST_VIEW
            : RouterName.SECOND_OPINION_REQUEST_VIEW,
        params: { id: state.item.RequestKey },
      };
    });

    return {
      state,
      RequestStatuses,
      store,
      RoleNames,
      print,
      RequestListType,
      t,
      view,
      ResponseStatuses,
      roleName,
      navigatorTo,
      cardInfo,
      DateFormat,
      insertDateTime,
      RequestKindTypes,
      ScheduledDateTime,
    };
  },
  components: { RequestProgress, RequestProgressDesc },
});
